/* Power reviews */

#pr-reviewdisplay {
  .p-w-r {
    .pr-review-display {
      .pr-review {
        .pr-rd-footer {
          .pr-helpful {
            &-yes {
              .pr-helpful-count {
                &:before {
                  content: '有幫助  (';
                  visibility: visible;
                }
                &:after {
                  content: ' )';
                  visibility: visible;
                }
              }
            }
            &-no {
              .pr-helpful-count {
                &:before {
                  content: '沒有幫助  (';
                  visibility: visible;
                }
                &:after {
                  content: ' )';
                  visibility: visible;
                }
              }
            }
          }
        }
      }
      .pr-rd-main-header {
        .pr-rd-review-total {
          clear: both;
        }
        .pr-multiselect[data-reactid-powerreviews$='的年齡'] {
          &::before {
            content: '進階篩選:';
            font-size: 12px;
            #{$renutriv} & {
              color: $color-white;
              font-family: $roboto-condensed;
            }
          }
        }
        .pr-rd-review-header-sorts {
          .pr-rd-sort-group {
            &::before {
              content: '排序依據: ';
            }
          }
        }
        .pr-rd-search-container {
          .pr-rd-search-reviews-icon-button {
            .pr-search-icon {
              &::after {
                content: '搜尋';
              }
            }
          }
        }
        .pr-rd-review-header-contents {
          width: 68%;
        }
      }
    }
    .pr-review-snapshot {
      .pr-review-snapshot-snippets {
        .pr-snippet-stars-reco-stars {
          .pr-snippet-rating-decimal {
            &:after {
              content: ' / 5';
            }
          }
        }
      }
    }
  }
}

.write_a_review__container {
  .p-w-r {
    .pr-checkbox {
      input[type='checkbox'] {
        #{$ldirection}: 0;
      }
      span {
        bottom: 4px;
        position: relative;
      }
    }
  }
}
