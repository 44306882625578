.section-store-locator {
  .store-locator {
    .doors-results {
      &__inner {
        .door-row {
          &__info {
            height: 150px;
          }
        }
      }
    }
  }
}
