ul.footer-social-links {
  li a.line {
    background-image: url('/media/images/Footer/line_logo.png') !important;
    background-repeat: no-repeat;
    height: 24px;
    margin: 0 0 0 19px;
    width: 24px;
    &:hover {
      background-position: -24px 0;
    }
  }
}

.page-sticky-footer {
  .page-sticky-footer__left .node-elc-nodeblock {
    border-left: none;
  }
}
