.privacy-policy-page .privacy-policy__toc {
  padding: 0 0 0 2em;
}

.page-utilities .page-utilities__search .el-search-block {
  padding-top: 0;
  margin-top: 30px;
}

.page-utilities__account {
  @media #{$medium-up} {
    left: -20px;
  }
  @media #{$xlarge-up} {
    left: 0;
  }
}

.trust-mark-elc-nodeblock {
  float: left;
}

.trust-mark-elc-nodeblock .trust_mark_header {
  position: relative;
  width: 80px;
  height: 80px;
}

.trust-mark-elc-nodeblock .trust_mark_header .trust_mark_header__link {
  position: absolute;
  width: 70px;
  height: 80px;
  top: -22px;
  background-image: url('/media/images/global/lo3.png');
  background-repeat: no-repeat;
  text-indent: -9999px;
}

.page-branding__logo,
.brand-aerin .page-branding__logo,
.brand-renutriv .page-branding__logo,
.is-alternate .page-branding__logo,
.brand-aerin .is-alternate .page-branding__logo,
.brand-renutriv .is-alternate .page-branding__logo,
.is-sticky .page-branding__logo,
.brand-aerin .is-sticky .page-branding__logo,
.brand-renutriv .is-sticky .page-branding__logo {
  float: right;
}

.brand-renutriv .trust-mark-elc-nodeblock .trust_mark_header .trust_mark_header__link,
.brand-renutriv .is-sticky .trust-mark-elc-nodeblock .trust_mark_header .trust_mark_header__link {
  background-image: url('/media/images/global/lo3-white.png');
}

.hero-tout .headline--large {
  font-size: 125px;
}

.hero-tout .text-promo__subhead {
  font-size: 24px;
}

.hero-tout .text-promo__subcopy {
  margin-top: 6px;
  margin-bottom: 6px;
}

.hero-tout .cta-button-wrapper .cta {
  font-size: 13px;
}

.hero-tout-fullwidth-wrapper .hero-tout-fullwidth .hero-tout__promo {
  padding-top: 15%;
}

.product-full__description a.selectbox,
.spp-product__mini-bag .spp-product__mini-bag-quantity {
  #{$ie8} & {
    line-height: 40px;
    height: 40px;
  }
}

.is-alternate {
  .trust-mark-elc-nodeblock {
    .trust_mark_header {
      .trust_mark_header__link {
        position: absolute;
        width: 70px;
        height: 80px;
        top: -13px;
        background-image: url('/media/images/global/lo3.png');
        background-repeat: no-repeat;
        text-indent: -9999px;
      }
    }
  }
}

body.section-setsgifts {
  .mpp__product-grid {
    .mpp__product:nth-child(3n) {
      margin: 0 0 60px;
    }
    .mpp__product:nth-child(3n + 1) {
      margin: 0 0 60px;
    }
    .mpp__product:nth-child(3n + 2) {
      margin: 0 7.6% 60px;
    }
  }
}

body.foundation-finder-page {
  .ff-results__recommendations {
    .ff-results__product {
      .sku-brief-editorial__image {
        width: 100%;
        height: auto;
      }
    }
  }
  .ff-results__matches {
    .product_brief__image-container {
      width: 100%;
      height: auto;
      .product_brief__image {
        width: 100%;
      }
    }
  }
}

#cboxLoadedContent {
  .sign-in-component {
    .sign-in-component__form--registration {
      div.form-item:nth-child(3) {
        margin-bottom: 0;
      }
      div.form-item {
        input#sign-in-component__NATIONAL_ID {
          display: none;
        }
      }
    }
  }
}

#viewcart,
#review {
  #offer_code {
    .offer_code_form_container {
      p:first-child {
        display: none;
      }
      p {
        margin: 5px 0;
      }
    }
  }
}

.mpp__product {
  .product__inventory-status li span {
    display: block;
    padding-bottom: 10px;
  }
}

.spp,
.brand-renutriv {
  .product-full__price-text {
    padding: 12px;
  }
  .product__inventory-status {
    .temp-out-of-stock span,
    .coming-soon span {
      padding: 12px;
    }
    .temp-out-of-stock a,
    .coming-soon a {
      line-height: 40px;
    }
    li.inactive,
    li.sold-out {
      padding: 12px;
    }
  }
}

#waitlist-overlay {
  padding: 80px 0 20px 0;
}

#waitlist_thanks {
  padding: 140px 0;
}

.waitlist_error {
  width: 450px;
}

.olapic-carousel-list-container .olapic-carousel-partial-container {
  background-image: url('/media/export/cms/olapic/OLAPIC_TOUT_FINAL_DESKTOP_Chinese.jpg');
}

#appointment-book-container {
  .artist {
    display: none;
  }
  .appointments-container {
    #booking-step1 {
      .artist-select-container {
        display: none !important;
      }
    }
    #booking-step3 {
      .total-time {
        margin-right: 15px;
      }
      .start-over {
        clear: both;
      }
      .book-appt-container {
        input[type='text'],
        input[type='tel'],
        input[type='email'],
        input[type='password'] {
          color: #040a2b !important;
        }
      }
    }
  }
  .location-select-section-content {
    &__subhead,
    &__description {
      font-size: 18px;
      font-family: $akzidenz;
      letter-spacing: 0;
    }
    &__description {
      margin-top: 0;
    }
  }
  .services-renutrive {
    .services-renutrive-content {
      max-width: 984px;
      margin: 40px auto;
      .service {
        width: 50%;
        .service-image {
          display: block;
        }
        .service-details {
          bottom: 0px;
          left: 45%;
          position: absolute;
          text-align: left;
          width: 52%;
          float: left;
          .lesson_description {
            .selection-time {
              display: inline-block;
            }
          }
          .service-title {
            font-size: 33px;
            margin-top: 0px;
            margin-bottom: 0px;
          }
          .selection-selector {
            float: left;
          }
        }
      }
    }
  }
}

@media #{$device-tablet-up} {
  .appt-book-overlay {
    &.appt-book-datepicker-overlay {
      .overlay-content-container {
        @include swap_direction(padding, 60px 20px);
      }
    }
  }
}

#popover_container {
  #hppopup {
    img {
      width: 100%;
    }
  }
}

#viewcart {
  #cboxOverlay {
    background: none;
  }
}

.signin-overlay-wrapper {
  #cboxContent {
    #cboxLoadedContent {
      max-height: 1000px;
      .selectBox-label {
        padding: 0px 12px 0px 10px;
      }
    }
  }
}

.overlay_on {
  overflow: hidden;
}

.appt-book-page-header-content {
  h2 {
    padding-top: 40px;
  }
}

.appt-book {
  .appt-book-section-content {
    .appt-book-content-header {
      .section-header,
      .section-header--description {
        font-size: 16px;
      }
    }
    .services-renutrive {
      .services-renutrive-header {
        h4 {
          padding-top: 40px;
          font-family: $bb-roman;
        }
      }
      .services-renutrive-content {
        .service {
          .service-details {
            .lesson_description {
              font-family: $bb-roman;
            }
          }
        }
      }
    }
  }
  .service-select {
    .service {
      .service-details {
        h3 {
          &.service-title {
            font-weight: normal;
          }
        }
      }
    }
  }
}

// 2018 Creative refresh
.section-re-nutriv,
.brand-renutriv {
  .page-header {
    &:not(#{$alt}),
    &#{$sticky}:not(#{$alt}) {
      .trust_mark_header {
        &__link {
          background-image: url('/media/images/global/lo3-white.png');
        }
      }
    }
    &:not(#{$alt}) {
      .page-utilities {
        .page-utilities__search-icon {
          background-image: url('/media/export/cms/gnav/search-icon-white.png');
        }
      }
    }
  }
}

.page-navigation__menu {
  @media #{$xlarge-up} {
    .menu-ref {
      &:first-of-type {
        padding-left: 100px;
      }
    }
  }
}
#{$alt-color-white} {
  .page-utilities {
    .page-utilities__search {
      .el-search-block {
        margin-top: 20px;
        @media #{$device-ipad_mini} {
          margin-top: 20px;
        }
        @media #{$large-only} {
          margin-top: 9px;
        }
        @media #{$xlarge-up} {
          margin-top: 20px;
        }
      }
    }
  }
}
#{$alt} {
  .page-navigation__hotswap {
    display: none;
  }
}

.page-product-promotions-setsgifts {
  &-advanced-night-repair-share-the-love-set {
    #colorbox {
      width: 400px !important;
      #cboxWrapper {
        width: 350px !important;
        #cboxContent {
          width: 300px !important;
        }
      }
    }
  }
}

.is-min-nav {
  .header-items {
    min-height: 65px;
  }
}

.product_brief__sub-panel {
  .product_brief__quantity-container {
    .product_brief__quantity {
      padding: 0;
      .selectBox-label {
        padding: 0 8px;
        letter-spacing: 0.05em;
      }
    }
  }
}

.zdc {
  &_popup {
    #cboxClose {
      background-image: $sprites-sprites;
    }
  }
}

#signin {
  .social-login {
    .custom-fb {
      margin-top: -10px;
    }
    .custom-line {
      margin-top: 10px;
    }
    &__container {
      h2 {
        display: none;
      }
    }
  }
}
