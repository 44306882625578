.welcome15-overlay {
  #cboxLoadedContent {
    .email_popover {
      height: auto !important;
    }
  }
}

#colorbox {
  &.colorbox {
    &__quickshop {
      .quickshop {
        &__attribute {
          height: auto;
        }
      }
    }
  }
}

.lp_survey_area {
  .lp_question_wrapper {
    .lp_radiobutton_wrapper {
      label {
        &::after,
        &::before {
          background: none;
          box-shadow: none;
          border: none;
        }
      }
    }
  }
}

.product-full {
  &__offer {
    background-color: $color-medium-gray-new;
    padding: 20px;
    margin-top: 30px;
  }
}
